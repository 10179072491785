import "./App.css";
import TopNavbar from "./components/TopNavbar";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Services from "./components/Services";
import Quote from "./components/Quote";
import Team from "./components/Team";
import Blog from "./components/Blog";
import CallToAction from "./components/CallToAction";
import Career from "./components/Career";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <TopNavbar />
                <Navbar />
                <Hero />
                <About />
                <Services />
                <Quote />
                <Team />
                <Career />
                <Blog />
                <CallToAction />
                <Footer />
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
