import React from "react";

const Services = () => {
  return <div>
    {/* Services Start */}
  <div className="container-fluid py-5" >
    <div className="container py-5" id="services">
      <div className="text-center mx-auto mb-5" style={{ maxWidth: 500 }} >
        <h1 className="display-5" >Professional Services</h1>
        <hr className="w-25 mx-auto text-primary" style={{ opacity: 1 }} />
      </div>
      <div className="row gy-4 gx-3" >
        <div className="col-lg-4 col-md-6 pt-5" >
          <div className="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0">
            <div className="service-icon p-3">
              <div>
                <i className="fa fa-2x fa-paint-brush" />
              </div>
            </div>
            <h3 className="mt-5" style={{color:"white"}}>Painting</h3>
            <a className="btn shadow-none text-secondary" href="#about" >
              View Detail
              <i className="bi bi-arrow-right ms-1" />
            </a>
          </div> 
        </div>
        <div className="col-lg-4 col-md-6 pt-5">
          <div className="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0">
            <div className="service-icon p-3">
              <div>
                <i className="fa fa-2x fa-solid fa-layer-group" /> 
              </div>
            </div>
            <h3 className="mt-5" style={{color:"white"}}>Landscaping</h3>
            <a className="btn shadow-none text-secondary" href="#about">
              View Detail
              <i className="bi bi-arrow-right ms-1" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 pt-5">
          <div className="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0">
            <div className="service-icon p-3">
              <div>
                <i className="fa fa-2x fa-brush" />
              </div>
            </div>
            <h3 className="mt-5" style={{color:"white"}}>Janitorial Work</h3>
            <a className="btn shadow-none text-secondary" href="#about">
              View Detail
              <i className="bi bi-arrow-right ms-1" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 pt-5">
          <div className="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0">
            <div className="service-icon p-3">
              <div>
                <i className="fa fa-2x fa-eraser" />
              </div>
            </div>
            <h3 className="mt-5" style={{color:"white"}}>Air Conditioning</h3>
            <a className="btn shadow-none text-secondary" href="#about">
              View Detail
              <i className="bi bi-arrow-right ms-1" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 pt-5">
          <div className="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0">
            <div className="service-icon p-3">
              <div>
                <i className="fa fa-2x fa-spray-can" />
              </div>
            </div>
            <h3 className="mt-5" style={{color:"white"}}>Roofing & Cleaning</h3>
            <a className="btn shadow-none text-secondary" href="#about">
              View Detail
              <i className="bi bi-arrow-right ms-1" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 pt-5">
          <div className="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0">
            <div className="service-icon p-3">
              <div>
                <i className="fa fa-2x fa-city" />
              </div>
            </div>
            <h3 className="mt-5" style={{color:"white"}}>City Sub Contracting</h3>
            <a className="btn shadow-none text-secondary" href="#about">
              View Detail
              <i className="bi bi-arrow-right ms-1" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Services End */}
  </div>;
};

export default Services;
