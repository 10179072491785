import React from "react";

const CallToAction = () => {
  return (
    <div>
      {/* Call To Action Start */}
      <div
        className="container-fluid bg-primary bg-call-to-action py-5"
        style={{ marginTop: 90 }}
      >
        <div className="container py-5">
          <div className="row g-0 justify-content-start">
            <div className="col-lg-6">
              <h1 className="display-5 mb-4">Do You Have Any Project?</h1>
              <p className="fs-4 fw-normal">
              Embark on a transformative journey with YesPaint! Whether it's an interior refresh, an exterior makeover, or a unique project, we're ready to bring your vision to life. Connect with us to discuss your ideas and let's create something beautiful together.
              </p>
              <a
                href="#contact"
                className="btn btn-secondary rounded-pill py-md-3 px-md-5 mt-4"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Call To Action Start */}
    </div>
  );
};

export default CallToAction;
