import React from "react";

const Hero = () => {
  return (
    <div>
      {/* Hero Start */}
      <div
        className="container-fluid bg-primary py-5 bg-hero"
        style={{ marginBottom: 90 }}
      >
        <div className="container py-5">
          <div className="row justify-content-start">
            <div className="col-lg-8 text-center text-lg-start">
              <h1 className="display-1 text-white">
                We Bring Your Home To Lively Colors
              </h1>
              <p className="fs-4 text-white mb-4">
              Welcome to YesPaint, your premier destination for transformative wall painting. With passion and precision, our skilled team brings creativity and personalized service to every project, ensuring your vision comes to life.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Hero End */}
    </div>
  );
};

export default Hero;
