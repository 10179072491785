import React from "react";

const Navbar = () => {
  return (
    <div>
      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg bg-dark navbar-dark shadow-sm px-5 py-3 py-lg-0">
        <a href="/" className="navbar-brand p-0">
          <img
            className="img logo-z"
            src="/static/img/paint-logo-w.png"
            alt="logo-img"
            id="home"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0 pe-2 border-end border-5 border-primary">
            <a href="/" className="nav-item nav-link active">
              Home
            </a>
            <a href="#about" className="nav-item nav-link">
              About Us
            </a>
            <a href="#contact" className="nav-item nav-link">
              Contact
            </a>
            <a href="#career" className="nav-item nav-link">
              Career
            </a>
            <a href="#services" className="nav-item nav-link">
              Services
            </a>
            <a href="#team" className="nav-item nav-link">
              Members
            </a>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                YesPaint
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    href="#"
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Product news
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#discount"
                  >
                    Discount
                  </a>
                </li>
              </ul>
            </li>
          </div>
          <div className="d-none d-lg-flex align-items-center ps-4">
            <i className="fa fa-2x fa-mobile-alt text-secondary me-3" />
            <div>
              <h5 className="text-primary mb-1">
                <small>Call Now</small>
              </h5>
              <h6 className="text-light m-0">+1 604 418 8416</h6>
            </div>
          </div>
        </div>
      </nav>

      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button> */}

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Product NEWS
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                We Use High Quality Paint Products From <br/> CLOVERDALE. SHERWIN
                WILLIAMS. AKZONOBEL JOTUN. FARROW & BALL. CANADIAN PAINT SUPPLIERS 
                 At Affordable Cost. We Supply The Same Directly To Our Clients
                And Customers Free From Additional Charges Accept Fair Labour
                Contract Of Painting.
              </p>

              <p>
                The Other Latest NEWS Regards To The Paint Industry
                Business.That Introduces New Japanese Technology Of Hands Free
                Wall Painting By Wall The Printer For Any Pattern Design Shape
                Or Photo Image To Restore On The Inner Wall Space.That Will Be
                Introduced Soon With Great Discounts and Benefits Management Yes
                Paint Inc.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="discount"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
              CASH - DISCOUNT - COUPONS - MEMBERS BENIFITS.
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                In This Discounts Are Subjected To The Coupons, First Order,
                Members Of Yes Paint Company And Well Instant Cash Discount 
                Payments By The Company.ON FINISH OF WORK & Final Payment <br/> Yes Paint Inc.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Navbar End */}
    </div>
  );
};

export default Navbar;
