import React from "react";

const About = () => {
  return (
    <div>
      {/* About Start */}
      <div className="container-fluid py-5" >
        <div className="container py-5">
          <div className="row gx-0 mb-3 mb-lg-0">
            <div className="col-lg-6 my-lg-5 py-lg-5" id="about">
              <div className="about-start bg-primary p-5" >
                <h1 className="display-5 mb-4 text-white" >Welcome To Yes Paint</h1>
                  <ul>
                    <li className="li-style">Painting</li>
                    <li className="li-style">Air Conditioning</li>
                    <li className="li-style">Heating & Electrical</li>
                    <li className="li-style">City Sub Contracting</li>
                    <li className="li-style">Steam Boilers Repairs</li>
                    <li className="li-style">Handyman Suppliers</li>
                    <li className="li-style">Landscaping</li>
                    <li className="li-style">Artificial Grass Turf Installation</li>
                    <li className="li-style">Plumbing & Repairs</li>
                    <li className="li-style">Property Care Co.</li>
                    <li className="li-style">Janitorial Work</li>
                    <li className="li-style">Heating & Electrical</li>
                    <li className="li-style">Garden Soil Suppliers</li>
                    <li className="li-style">Gravels Suppliers</li>
                    <li className="li-style">River Rocks Suppliers</li>
                    <li className="li-style">Botanical - Nursery</li>
                    <li className="li-style">Lawn Movers & Snow Removal</li>
                    <li className="li-style">Roofing & Cleaning</li>
                  </ul>
              </div>
            </div>
            <div className="col-lg-6" style={{ minHeight: 400 }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100"
                  src="/static/img/about-1.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
    </div>
  );
};

export default About;
