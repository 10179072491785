import React, { useState } from "react";

const Quote = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const [number, setNumber] = useState();

  // Function to handle number input validation
  const handleNumberInput = (event) => {
    // Get the input value
    let value = event.target.value;

    // Remove non-digit characters
    let digitValue = value.replace(/\D/g, "");

    // Limit the length to 10 characters
    digitValue = digitValue.slice(0, 10);

    // Update the state
    setNumber(digitValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Validate each field as it is changed
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorMessage = "";

    switch (name) {
      case "name":
        const words = value.trim().split(/\s+/); // Split the value into words
        errorMessage =
          words.length <= 15 ? "" : "Name must be limited to 15 words";
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        errorMessage =
          value.trim() && emailRegex.test(value)
            ? ""
            : "Valid email is required";
        break;
      case "number":
        const numberRegex = /^\d{10}$/; // 10 digits
        errorMessage =
          value.trim() && numberRegex.test(value)
            ? ""
            : "Number must be 10 digits";
        break;
      case "message":
        errorMessage = value.trim() ? "" : "Message is required";
        break;
      default:
        break;
    }

    setErrors({
      ...errors,
      [name]: errorMessage,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate all fields before submission
    const isValid = Object.values(errors).every((error) => error === "");

    if (isValid) {
      // Perform the form submission logic (e.g., display success message)
      console.log("Form submitted:", formData);
    } else {
      console.log("Form validation failed");
    }
  };
  return (
    <div>
      <div
        className="container-fluid bg-primary bg-quote py-5"
        id="contact"
        style={{ margin: "90px 0" }}
      >
        <div className="container py-5">
          <div className="row g-0 justify-content-start">
            <div className="col-lg-6">
              <div className="bg-white text-center p-5">
                <h1 className="mb-4">Contact Us</h1>
                <form action="https://formspree.io/f/xrgwnkae" method="POST">
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <input
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        type="text"
                        className="form-control bg-light border-0"
                        placeholder="Contact Name"
                        style={{ height: 55 }}
                        min="1"
                        max="15"
                        maxlength="30"
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control bg-light border-0"
                        placeholder="E-mail"
                        style={{ height: 55 }}
                        maxlength="30"
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        id="numberInput"
                        name="number"
                        value={number}
                        onChange={handleNumberInput}
                        className="form-control bg-light border-0"
                        placeholder="Contact Number"
                        style={{ height: 55 }}
                        maxlength="10"
                        pattern="[0-9]{10}"
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="address"
                        name="address"
                        className="form-control bg-light border-0"
                        placeholder="Job location"
                        style={{ height: 55 }}
                        maxlength="45"
                        required
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control bg-light border-0 py-3"
                        rows={2}
                        placeholder="Work Descriptions"
                        defaultValue={""}
                        name="message"
                        maxlength="150"
                      />
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
