import React from "react";

const Team = () => {
  return (
    <div>
      {/*  Start */}
      <div className="container-fluid py-5" id="team">
        <div className="container py-5">
          <div className="text-center mx-auto mb-5" style={{ maxWidth: 500 }}>
            <h1 className="display-5">Dedicated Team Members</h1>
            <hr className="w-25 mx-auto text-primary" style={{ opacity: 1 }} />
          </div>
          <div className="row g-3">
            <div className="col-lg-4 col-md-6">
              <div className="team-item">
                <img
                  className="img-fluid w-100"
                  src="/static/img/team-1.jpg"
                  alt=""
                />
                <div className="team-text">
                  <div className="team-social">
                    <a
                      className="btn btn-lg btn-secondary btn-lg-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                    <a
                      className="btn btn-lg btn-secondary btn-lg-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a
                      className="btn btn-lg btn-secondary btn-lg-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                  <div className="mt-auto mb-3">
                    <h4 className="mb-1">KAVISH RC</h4>
                    <span className="text-uppercase">LEAD PAINTER</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="team-item">
                <img
                  className="img-fluid w-100"
                  src="/static/img/team-2.jpg"
                  alt=""
                />
                <div className="team-text">
                  <div className="team-social">
                    <a
                      className="btn btn-lg btn-secondary btn-lg-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                    <a
                      className="btn btn-lg btn-secondary btn-lg-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a
                      className="btn btn-lg btn-secondary btn-lg-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                  <div className="mt-auto mb-3">
                    <h4 className="mb-1">D.C. SHARMA</h4>
                    <span className="text-uppercase">SUPERVISOR</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="team-item">
                <img
                  className="img-fluid w-100"
                  src="/static/img/team-3.jpg"
                  alt=""
                />
                <div className="team-text">
                  <div className="team-social">
                    <a
                      className="btn btn-lg btn-secondary btn-lg-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                    <a
                      className="btn btn-lg btn-secondary btn-lg-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a
                      className="btn btn-lg btn-secondary btn-lg-square rounded-circle me-2"
                      href="#"
                    >
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                  <div className="mt-auto mb-3">
                    <h4 className="mb-1">HARI OM</h4>
                    <span className="text-uppercase">MANAGER</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}
    </div>
  );
};

export default Team;
