// BackToTopButton.js

import React, { useState, useEffect } from 'react';
import './BackToTopButton.css'; // Import your CSS file for styling

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle scroll events and update the visibility state
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 200; // Adjust this threshold as needed

    setIsVisible(scrollTop > threshold);
  };

  // Effect to attach and remove scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to scroll to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling behavior
    });
  };

  return (
    <a
      href=""
      className={`btn btn-lg btn-primary btn-lg-square rounded back-to-top ${
        isVisible ? 'visible' : 'hidden'
      }`}
      onClick={scrollToTop}
    >
      <i className="bi bi-arrow-up" />
    </a>
  );
};

export default BackToTopButton;
